import styled from 'styled-components';
import * as SBS from '../ButtonShared.styles';
import IconButton from '../IconButton/IconButton';
const StyledButton = styled(IconButton) `
  ${SBS.Icon} {
    transform: rotate(-180deg);
  }
`;
export default {
    Button: StyledButton,
};
