import React from 'react';
import * as SBS from '../ButtonShared.styles';
import S from './IconButton.styles';
const IconButton = ({ className, icon, themeOption, title, ...otherProps }) => {
    let iconComponent;
    if (typeof icon === 'string') {
        iconComponent = (React.createElement(SBS.Icon, { as: "svg", "aria-label": title },
            React.createElement("use", { href: icon })));
    }
    else {
        iconComponent = React.createElement(SBS.Icon, { as: icon, "aria-label": title });
    }
    return (React.createElement(S.Button, { className: className, themeOption: themeOption, "data-theme": themeOption, type: "button", ...otherProps }, iconComponent));
};
export default IconButton;
