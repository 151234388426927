import brand from '@helpers/brand';
import { Device, from } from '@helpers/media';
import styled, { css } from 'styled-components';
export function buttonTheme(theme) {
    const value = css `
    ${theme.bgColor &&
        css `
      --buttonBgColor: ${theme.bgColor};
    `}

    ${theme.borderColor &&
        css `
      --buttonBorderColor: ${theme.borderColor};
    `}

    ${theme.fgColor &&
        css `
      --buttonColor: ${theme.fgColor};
    `}
  `;
    return value;
}
export const sharedButtonStyles = css `
  display: inline-flex;
  align-items: center;
  border: 0;
  background-color: ${brand.black};
  border: 1px solid ${brand.black};
  border-radius: 8px;
  color: ${brand.white};
  height: 48px;
  justify-content: flex-start;
  margin: 0;
  outline: none;
  padding: 0 24px;
  place-content: center;
  place-items: center;
  text-decoration: none;
  transition: background-color 0.15s ease-out, background-size 0.15s ease-out,
    border-color 0.15s ease-out, color 0.15s ease-out, opacity 0.15s ease-out,
    transform 0.15s ease-out;
  user-select: none;
  width: auto;

  &:disabled {
    opacity: 0.5;
  }

  &:focus {
    outline: none;
  }

  ${() => Icon} {
    margin-left: 12px;
  }

  @media ${from(Device.MobileLarge)} {
    min-width: 150px;
  }
`;
export const Icon = styled.span `
  display: block;
  height: 20px;
  width: 20px;

  @media ${from(Device.Tablet)} {
    height: 24px;
    width: 24px;
  }
`;
export const Text = styled.span `
  color: currentColor;

  font-size: 18px;
  line-height: 25px;
`;
